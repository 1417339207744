<template>
  <div id="app">
    <FirebaseWebPush v-if="auth && needWebPush"/>
    <OnesignalPush v-if="auth && onesignalConfig" :onesignal-config="onesignalConfig"/>
    <UpdateBanner v-if="false"/>
    <div class="site-container">
      <Header/>
      <router-view/>
      <Preloader/>
    </div>
    <AppBanner/>
    <BonusBanner/>
    <Tocha :player="player" :url="tochaUrl" v-if="tochaUrl"/>
    <Koleso
        :api="kolesoApi"
        :app-url="appUrl"
        :need-app="appUrl && kolesoOnlyApp && !isApp"
        v-if="auth"
        :on-win="onWin"
        :need-deposit-text="kolesoNeedDepositText"
        :custom-error-text="needIdentification ? $t('koleso.needIdentification') : ''"
        :custom-error-url="needIdentification ? '/profile/' : ''"
        :key="kolesoKey"
        style="position: fixed"
    />
  </div>
</template>

<script>
import Header from "./components/Header/Header";
import Preloader from "./components/Ui/Preloader";
import Tocha from "./components/Tocha";
import AppBanner from "./components/AppBanner/AppBanner";
import BonusBanner from "./components/BonusBanner/BonusBanner.vue";
import Koleso from "@portals/koleso-widget/src/Koleso";
import {KolesoApi} from "./store/KolesoApi";
import UpdateBanner from "@/components/UpdateBanner";
import FirebaseWebPush from "@/components/FirebaseWebPush.vue";
import OnesignalPush from "@/components/OnesignalPush.vue";

export default {
  name: "App",
  components: {
    OnesignalPush,
    FirebaseWebPush,
    UpdateBanner,
    AppBanner,
    BonusBanner,
    Tocha,
    Header,
    Preloader,
    Koleso
  },
  data() {
    return {
      kolesoApi: new KolesoApi(this.$store)
    }
  },
  computed: {
    needIdentification() {
      return !this.$store.state.identityConfirmed
    },
    tochaUrl() {
      return this.$store.state.config.tochaUrl
    },
    appUrl() {
      return this.$store.state.config.appUrl
    },
    kolesoKey() {
      return this.$store.state.balance + this.kolesoNeedDepositText
    },
    kolesoNeedDepositText() {
      return "для активации пополните счёт на " + this.$store.state.minKolesoDeposit + " тенге"
    },
    kolesoOnlyApp() {
      return this.$store.state.config.kolesoOnlyApp
    },
    isApp() {
      return Boolean(window.Android);
    },
    auth() {
      return this.$store.state.auth;
    },
    needWebPush() {
      return Boolean(window.Android) === false
          && (
              window.location.hostname === "loto37.club"
              || window.location.hostname === "club37.club"
              || window.location.hostname === "localhost"
          );
    },
    onesignalConfig() {
      return this.$store.state.config.onesignalConfig
    },
    player() {
      const id = this.$store.state.player_id;
      const label = this.$store.state.player_label;
      const noDeposit = !this.$store.state.hasDeposit;

      if (!id) {
        return null;
      }

      return ['(id: ' + id + ')', maskedLabel(label), noDeposit ? '(без депозита)' : ''].join(' ');
    },
  },
  methods: {
    onWin() {
      this.$store.dispatch('balance');
    }
  }
};


function maskedLabel(label) {

  if (!/^\d+$/.test(label)) {
    return label
  }

  return label.substring(0, 4) + "..." + label.substring(label.length - 4);
}
</script>

<style lang="scss">
#app {
  .site-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .toggler-conatiner {
    transform: rotate(-90deg) scale(.7);
    position: fixed;
  }
}
</style>
