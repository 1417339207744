<template>
  <section class="login">
    <div class="login__container">
      <div :class="['login__main', { 'in-error': formError }]">
        <p class="login__title">Вход</p>
        <TelInput
            v-model="userTel"
            :title="$t('login.phoneInput.label')"
            :placeholder="$t('login.phoneInput.placeholder')"
            :validate="$v.userTel"
            @input="formButtonLock = false"
            :customStyle="{marginBottom: '25px'}"
        />
        <PasswordInput
            :title="$t('login.passwordInput.label')"
            :placeholder="$t('login.passwordInput.placeholder')"
            :error="$t('login.passwordInput.required')"
            :validate="$v.userPassword"
            v-model="userPassword"
            @input="formButtonLock = false"
        />

        <router-link
            :to="{ name: 'recover' }"
            class="link__orange pass"
        >
          {{ $t('login.forgotPasswordLink') }}
        </router-link>

        <div class="login__events">
          <button
              class="btn__orange comeIn"
              :disabled="$v.userTel.$error || $v.userPassword.$error || formButtonLock"
              @click="logIn()"
          >{{ $t('login.loginButton') }}
          </button>
          <p>{{ $t('login.registerLinkHeading') }}</p>
          <router-link :to="{ name: 'registration' }" class="link__orange">
            {{ $t('login.registerLink') }}
          </router-link>

        </div>
      </div>

      <div :class="['social', { 'in-error': formError }]">
        <br/>
        <Social/>
      </div>
      <div :class="['login__error', { 'in-error': formError }]">
        <p>{{ $t('login.invalidCredentials') }}</p>
        <router-link
            :to="{ name: 'recover' }"
            class="btn__orange-border"
        >
          {{ $t('login.restorePasswordButton') }}
        </router-link>
      </div>

    </div>
  </section>
</template>

<script>
import {required, minLength} from "vuelidate/lib/validators";
import TelInput from "../Ui/TelInput";
import PasswordInput from "../Ui/PasswordInput";
import Social from "./Social";

export default {
  components: {
    Social,
    TelInput,
    PasswordInput,
  },
  props: {
    page: {
      type: Number,
      default: 0,
      requiered: false,
    },
  },
  data() {
    return {
      userTel: this.$route.params.phone || '',
      userPassword: "",
      authError: false,
      passError: false,
      telError: false,
      formError: false,
      formButtonLock: false,
    };
  },
  validations: {
    userTel: {
      required,
      minLength: minLength(11),
    },
    userPassword: {
      required,
    },
  },
  methods: {
    telegramLogin(user) {
      console.log(user);
    },
    logIn() {
      this.$v.userTel.$touch();
      this.$v.userPassword.$touch();
      if (this.$v.$error) {
        return
      }

      this.$store.dispatch('login', {tel: this.userTel, pass: this.userPassword}).then(success => {
        if (success) {
          const to = this.$route.params.return || '/';
          this.$router.push(to);
        } else {
          this.formError = true;
          this.formButtonLock = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.social.in-error {
  @media (max-width: 900px) {

  }
}

.login {
  background-color: #1b1c37;
  padding: 0 40px;
  min-height: 503px;
  position: absolute;
  top: 65px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;

  .login__container {
    height: 100%;
    min-height: 530px;
    position: relative;

    .login__main {
      position: relative;
      margin-bottom: 20px;
      transition: 0.2s;
      transition-delay: 0.2s;

      .login__title {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: #ffffff;
      }

      .link__orange.pass {
        max-width: max-content;
        display: flex;
        margin-left: auto;
        position: relative;
        top: -10px;
      }

      &.in-error {
        top: 0;

        & .link__orange.pass {
          display: none;
        }
      }
    }

    .login__error {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      transition-delay: 0s;

      p {
        margin-bottom: 15px;
        font-weight: normal;
        font-size: 16px;
        line-height: 130.4%;
        color: #ffffff;
      }

      &.in-error {
        visibility: visible;
        opacity: 1;
        transition-delay: 0.2s;
      }
    }

    .login__events {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .comeIn {
        margin-bottom: 16px;
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 130.4%;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &.active-main {
    right: -100%;
    left: 100%;
    opacity: 0;
  }

  &.active-next {
    right: -100%;
    left: 100%;
    opacity: 0;
  }
}
</style>
