import Login from "../components/Auth/Login";
import Logout from "../components/Auth/Logout";
import Confirm from "../components/Auth/Registration/Confirm";
import Complete from "../components/Auth/Registration/Complete";
import RegistrationCompleted from "../components/Auth/Registration/Completed";

import Request from "../components/Auth/Recover/Request";
import ConfirmRecover from "../components/Auth/Recover/Confirm";
import Reset from "../components/Auth/Recover/Reset";
import RecoverCompleted from "../components/Auth/Recover/Completed";
import Auth from "../views/Auth";
import GoogleRedirectHandler from "../components/Auth/Google/GoogleRedirectHandler";
import MailruRedirectHandler from "../components/Auth/Mailru/MailruRedirectHandler";
import UnconfirmedRegistration from "../components/Auth/Registration/UnconfirmedRegistration";

export default [
    {
        path: '/auth',
        component: Auth,
        children: [
            {
                name: 'login.google',
                path: 'login/google',
                component: GoogleRedirectHandler,
            },
            {
                name: 'login.mailru',
                path: 'login/mailru',
                component: MailruRedirectHandler,
            },
            {
                name: 'login',
                path: 'login/:return?',
                component: Login,
                props: true
            },
            {
                name: 'logout',
                path: 'logout',
                component: Logout,
            },
            {
                name: 'registration',
                path: 'registration',
                component: UnconfirmedRegistration,
            },
            {
                name: 'registration.confirm',
                path: 'registration/confirm/:process_id/:tel',
                component: Confirm,
                props: true
            },
            {
                name: 'registration.complete',
                path: 'registration/complete/:process_id',
                component: Complete,
                props: true
            },
            {
                name: 'registration.completed',
                path: 'registration/completed',
                component: RegistrationCompleted,
                props: true
            },
            {
                name: 'recover',
                path: 'recover',
                component: Request,
            },
            {
                name: 'recover.confirm',
                path: 'recover/confirm',
                component: ConfirmRecover,
                props: true
            },
            {
                name: 'recover.reset',
                path: 'recover/reset',
                component: Reset,
                props: true
            },
            {
                name: 'recover.completed',
                path: 'recover/completed',
                component: RecoverCompleted,
                props: true
            }
        ]
    },

]
